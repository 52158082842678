import { FormikErrors, useFormik } from "formik"

import { TOrganizationRoleForm } from "src/components/Organizations/OrganizationCreate/OnboardingCreateOrganizationWizard/OrganizationRoleStep/OrganizationRoleStep"
import { useTranslate } from "src/i18n/useTranslate"

export function useOrganizationRoleForm({
  initialValues,
  onSubmit,
}: {
  initialValues: TOrganizationRoleForm
  onSubmit: (values: TOrganizationRoleForm) => void
}) {
  const { t, langKeys } = useTranslate()

  const roleForm = useFormik<TOrganizationRoleForm>({
    initialValues,
    validateOnBlur: true,
    validate: (values) => {
      const errors: FormikErrors<TOrganizationRoleForm> = {}
      if (!values.profilingRole) {
        errors.profilingRole = t(langKeys.create_organization_role_error_text)
      }
      return errors
    },
    onSubmit,
  })

  return roleForm
}
